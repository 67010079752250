<template>
  <!-- prettier-ignore -->
  <basic-select
    v-model="model"
    :disabled="$props.disabled"
    :label="$t('person.sex')"
    :name="$props.name"
    :options="genderList"
    :readonly="$props.readonly"
    :v="$props.v"
  />
</template>

<script setup>
import { computed, watch } from 'vue'
import useI18n from '@/hooks/useI18n'

import BasicSelect from '@/components/Basic/Select'

import { GENDER } from '@/config/constants'

// HOOKS
const { t } = useI18n()

// INIT
const model = defineModel({ type: [String] })
const props = defineProps({
  age: {
    type: Number,
    default: null,
  },

  disabled: {
    type: Boolean,
    default: false,
  },

  disableUnborn: {
    type: Boolean,
    default: false,
  },

  modelValue: {
    type: String,
    default: '',
  },

  name: {
    type: String,
    default: 'gender',
  },

  readonly: {
    type: Boolean,
    default: false,
  },

  theme: {
    type: String,
    default: 'transparent',
  },

  v: {
    type: Object,
    default: () => {},
  },
})

// COMPUTED
const genderList = computed(() => {
  return Object.values(GENDER)
    .map(value => {
      const isUnbornGender = value === GENDER.UNBORN
      const disabled = isUnbornGender && isUnbornDisabled.value ? true : isGenderDisabled.value && !isUnbornGender
      return {
        value,
        label: t(`person.gender.${value.toLowerCase()}`),
        disabled,
      }
    })
    .filter(i => !i.disabled)
})

const isGenderDisabled = computed(() => {
  return props.age < 0
})

const isUnborn = computed(() => {
  return model.value === GENDER.UNBORN
})

const isUnbornDisabled = computed(() => {
  return (props.age !== null && props.age >= 0) || props.disableUnborn
})

// METHODS
function autoSelect() {
  if (props.age < 0) {
    onSelect(GENDER.UNBORN)
  } else if (props.age >= 0 && isUnborn.value) {
    onSelect(null)
  }
}

function onSelect(value) {
  model.value = value
}

// WATCHERS
watch(
  () => props.age,
  () => autoSelect()
)
</script>
